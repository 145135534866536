import React from 'react';
import { Container } from 'react-bootstrap';

const Publications = () =>{
    return (
      <Container>
        <h1 className="m-2 border-bottom border-info"> প্রকাশনা </h1>
      </Container>
      );
};

export default Publications;

