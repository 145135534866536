import React from 'react';
import { Container } from 'react-bootstrap';

const Daawah = () =>{
    return (
      <Container>
        <h1 className="m-2 border-bottom border-info">দাওয়াহ</h1>
      </Container>
      );
};

export default Daawah;
